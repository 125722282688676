<template>
  <v-dialog v-model="dialog" width="300">
    <template v-slot:activator="{ on, attrs }">
      <v-btn depressed color="primary" v-bind="attrs" v-on="on">
        新增排程
      </v-btn>
    </template>

    <v-card>
      <v-card-title>請選擇設定排程類型</v-card-title>

      <v-card-text>
        <v-btn
          text
          color="primary"
          :to="`/school/${tagId}/schedule/create`"
          width="100%"
          class="text-h6"
          large
        >
          電驛控制
        </v-btn>
        <v-btn
          v-permission-with-city="['keelung']"
          text
          color="primary"
          :to="`/school/${tagId}/schedule/create/temperature`"
          width="100%"
          class="mt-2 text-h6"
          large
        >
          溫度設定
        </v-btn>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: undefined,
  mixins: [],
  components: {},
  props: {
    tagId: {
      type: String
    }
  },
  data: function () {
    return {}
  },
  computed: {},
  watch: {},
  created: function () {},
  mounted: function () {},
  destroyed() {},
  methods: {}
}
</script>

<style></style>
