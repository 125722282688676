<template>
  <div class="pa-3">
    <v-row justify="end">
      <v-col cols="auto" v-permission-without-city="['keelung']">
        <v-btn
          depressed
          color="primary"
          :to="`/school/${tagId}/schedule/create`"
        >
          新增排程
        </v-btn>
      </v-col>
      <v-col cols="auto" v-permission-with-city="['keelung']">
        <createBtn :tagId="tagId" />
      </v-col>
    </v-row>
    <v-data-table
      v-if="items"
      class="schedule-list rounded-0"
      :headers="headers"
      :items="items"
      :items-per-page="items.length"
      hide-default-footer
      no-data-text="尚無排程"
    >
      <template v-slot:[`item.type`]="{ value }">
        {{ value === 'week' ? '每週循環' : '例外排程' }}
      </template>
      <template v-slot:[`item.groupIdList`]="{ value }">
        <v-chip
          v-for="(groupId, key) in value"
          :key="key"
          class="mr-2"
          v-show="groups && Array.isArray(groups) && groups.length > 0"
        >
          {{ groupName[groupId] || '-' }}
        </v-chip>
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <v-btn
          outlined
          small
          color="primary"
          :to="`${$route.path}/${item.id}/${
            item.actionType === 1 ? 'temperature' : 'relay'
          }`"
          class="mr-2"
          >編輯</v-btn
        >
        <v-btn outlined small color="red" @click="deleteSchedule(item.id)">
          刪除
        </v-btn>
      </template>
    </v-data-table>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Schedule from '@/api/ems/Schedule'
import createBtn from './createBtn'

export default {
  name: undefined,
  mixins: [],
  components: {
    createBtn
  },
  props: {
    tagId: {
      type: String
    }
  },
  data: function () {
    return {
      overlay: false,
      headers: [
        {
          text: '排程名稱',
          value: 'name',
          class: 'ems-bluegrey--text subtitle-1'
        },
        {
          text: '類型',
          value: 'type',
          class: 'ems-bluegrey--text subtitle-1'
        },
        {
          text: '執行對象',
          value: 'groupIdList',
          class: 'ems-bluegrey--text subtitle-1'
        },
        {
          text: '最後編輯日期',
          value: 'updateTime',
          class: 'ems-bluegrey--text subtitle-1'
        },
        {
          text: '',
          value: 'action',
          class: 'ems-bluegrey--text subtitle-1',
          sortable: false
        }
      ],
      items: null
    }
  },
  computed: {
    ...mapGetters({
      groups: 'groups/groups'
    }),
    groupName() {
      if (!this.groups || !Array.isArray(this.groups)) {
        return {}
      }

      const group = {}
      this.groups.forEach((item) => {
        group[item.id] = item.name
      })
      return group
    }
  },
  watch: {},
  created: function () {},
  mounted: function () {
    this.getGroups(this.tagId)
    this.getData()
  },
  destroyed() {},
  methods: {
    ...mapActions({
      getGroups: 'groups/getGroups',
      showMsg: 'snackbar/show'
    }),
    getData() {
      this.overlay = true
      Schedule.index(this.tagId)
        .then((response) => {
          if (!response.data || !Array.isArray(response.data)) {
            console.error(response)
            return
          }
          this.items = response.data
        })
        .finally(() => {
          this.overlay = false
        })
    },
    deleteSchedule(scheduleId) {
      this.overlay = true
      Schedule.delete(scheduleId)
        .then(() => {
          this.showMsg({
            message: `刪除完成`,
            closable: true,
            time: 2000
          })
          this.getData()
        })
        .catch((error) => {
          console.log(error)
          this.showMsg({
            message: `刪除失敗`,
            color: 'error',
            closable: true,
            time: 2000
          })
        })
        .finally(() => {
          setTimeout(() => {
            this.overlay = false
          }, 1000)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.schedule-list::v-deep {
  &.v-data-table {
    background-color: transparent;
  }
  &.v-data-table > .v-data-table__wrapper > table {
    border-collapse: separate;
    border-spacing: 0 1em;
    th,
    td {
      border-bottom: none !important;
    }
    td {
      background-color: #fff;
      color: var(--v-accent-base);
      font-size: 1rem !important;
    }
  }
}
</style>
